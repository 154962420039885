import React, { useState, useEffect, Fragment, useRef } from 'react';
import logo from '../assets/images/yovu-logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from "react-router";

import office365 from '../assets/images/office365.png';
import googleImage from '../assets/images/google.png';

import '../assets/scss/app-styles/signin.scss'

import Spinner from '../components/Widgets/Spinner';

import axios from "axios";
import { Authenticate, SignIn, Username, Password, CloudId, Passcode } from '../constant';

const AppSignin = ({ history }) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cloudId, setCloudId] = useState("");
    const [passcode, setPasscode] = useState("");
    const [mfa, setMfa] = useState({});
    const [subscribers, setSubscribers] = useState([]);
    const [ssoType, setSsoType] = useState('');
    const [ssoToken, setSsoToken] = useState('');
    const [accessToken, setAccessToken] = useState(null);
    const [loading, setLoading] = useState(false);

    // // Function to generate a nonce
    const generateNonce = () => {
        return Math.random().toString(36).substring(2) + Date.now().toString(36);
    };

    useEffect(() => {
        const url = new URL(window.location.href);
        const myCloudId = url.searchParams.get("cloud_id");
        setCloudId(myCloudId.replaceAll("*", ""));
    }, []);

    useEffect(() => {
        // Get the current URL
        const currentUrl = window.location.href;

        // Check if the URL starts with 'csc:'
        if (currentUrl.startsWith('csc:')) {
            // Extract the token by removing 'csc:' prefix
            const token = currentUrl.replace('csc:', '');

            // Set the token in state
            setAccessToken(token);

            console.log("Captured Token:", token);

            // Optionally, you can send this token to the server or handle it further
        }
    }, []);

    // Handle Google sign-in by opening in an external browser
    const handleGoogleSignIn = () => {
        const nonce = generateNonce(); // Generate a random nonce
        const googleAuthURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/authCallback&response_type=id_token&scope=profile email&nonce=${nonce}`;

        // Open the Google SSO page in an external browser
        window.open(googleAuthURL, '_blank'); // Open in external browser
    };

    // Handle Microsoft sign-in by opening in an external browser
    const handleMicrosoftSignIn = () => {
        const nonce = generateNonce(); // Generate a random nonce
        const microsoftAuthURL =`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MS_CLIENT_ID}&response_type=token%20id_token&redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/authCallback&scope=openid%20profile%20email%20offline_access&response_mode=fragment&nonce=${nonce}`;
        // Open the Microsoft SSO page in an external browser
        window.open(microsoftAuthURL, '_blank'); // Open in external browser
    };

    const appLogin = () => {
        // axios call here
        //TODO:  also get profile image here of user and store it in profileURL
        setLoading(true);
        const creds = {
            "cloud_id": cloudId,
            "username": username,
            "password": password
        };
        axios.post(`${process.env.REACT_APP_API_URL}appSignin`, creds)
            .then(response => {
                setLoading(false);
                if (response.data.mfa === "mfa_required") {
                    setMfa(response.data);
                    return

                }
                let code = "csc:" + username + ":" + btoa(password) + "@" + cloudId
                if (process.env.REACT_APP_BUILD === "dev") {
                    code += "*"
                }
                window.location.href = code

                toast.success("Signing In Please Wait!");
            })
            .catch(err => {
                setLoading(false);
                toast.error("Failed to login");
                console.log(err);

            });

    }


    const goBack = () => {
        setMfa({});
        setSubscribers([]);
    }

    const secondFactorAppLogin = () => {
        setLoading(true);
        const payLoad = {
            "cloud_id": cloudId,
            "username": username,
            "password": password,
            "passcode": passcode,
            "mfa_type": mfa.mfa_type,
            "mfa_vendor": mfa.mfa_vendor,
            "access_token": mfa.access_token,
        }
        axios.post(`${process.env.REACT_APP_API_URL}secondFactorAppSignin`, payLoad)
            .then(response => {
                setLoading(false);
                let code = "csc:" + username + ":" + btoa(password) + "@" + cloudId
                if (process.env.REACT_APP_BUILD === "dev") {
                    code += "*"
                }
                window.location.href = code;
                toast.success("Signing In Please Wait!");
            })
            .catch(err => {
                setLoading(false);
                toast.error("Failed to login");
                console.log(err);

            });
    }

    const ssoSignin = async (token, type, subscriberLogin) => {
        setLoading(true);
        const creds = {
            "cloud_id": cloudId,
            "type": type,
            "idToken": token
        };
        if (subscriberLogin) {
            creds.subscriber_login = subscriberLogin;
        }
        axios.post(`${process.env.REACT_APP_API_URL}ssoSignin`, creds)
            .then(response => {
                setLoading(false);
                if (response.data.subscribers && response.data.subscribers.length > 0) {
                    console.log("Setting subscribers")
                    setSubscribers(response.data.subscribers)
                    setSsoType(type)
                    setSsoToken(token)
                    return;
                }
                if (response.data.mfa === "mfa_required") {
                    setUsername(response.data.username);
                    setPassword(type);
                    setMfa(response.data);
                    return

                }
                let code = "csc:" + response.data.username + ":" + btoa(type) + "@" + cloudId
                if (process.env.REACT_APP_BUILD === "dev") {
                    code += "*"
                }
                window.location.href = code

                toast.success("Signing In Please Wait!");
            })
            .catch(err => {
                setLoading(false);
                toast.error("Failed to login");
                console.log(err);

            });
    }

    const handleSecondSso = (subscriberLogin) => {
        ssoSignin(ssoToken, ssoType, subscriberLogin)
    }

    return (

        <Fragment>
            <div className="page-wrapper">
                <div className="auth-bg">
                    <div className="authentication-box">
                        <div className="text-center"><img width="300" src={logo} alt="" /></div>
                        <div className="card mt-4">
                            <div className="card-body">
                                <div className="text-center">
                                    {loading && (
                                        <Spinner />
                                    )}
                                    <h4>{SignIn}</h4>
                                    <h6>
                                        {
                                            subscribers.length > 0 ?
                                                "Choose a Subscriber to Sign In As"
                                                :
                                                Object.keys(mfa).length === 0 ?
                                                    "Enter your Username and Password"
                                                    :
                                                    "Enter Passcode From Google Authenticator"
                                        }
                                    </h6>
                                </div>

                                {
                                    subscribers.length > 0 ?

                                        <div>
                                            <div className="subscriber-list">
                                                {
                                                    subscribers.map((subscriber, index) => {
                                                        return (
                                                            <div key={index} className="subscriber" onClick={e => handleSecondSso(subscriber.subscriber_login)} >
                                                                <span>{subscriber.subscriber_login}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <p className="go-back" onClick={goBack}>Go Back</p>
                                        </div>
                                        :
                                        Object.keys(mfa).length === 0 ?

                                            <form className="theme-form">
                                                <div className="form-group">
                                                    <label className="col-form-label pt-0">{Username}</label>
                                                    <input className="form-control signin-input" type="text" name="username"
                                                        value={username}
                                                        onChange={e => setUsername(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-form-label">{Password}</label>
                                                    <input className="form-control signin-input" type="password" name="password"
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)} />
                                                </div>

                                                <div className="form-group form-row mt-3 mb-0">
                                                    <button className="btn btn-primary btn-block signin-button"
                                                        type="button" onClick={() => appLogin()} >{SignIn}</button>
                                                </div>

                                                <div className="form-group form-row mt-3 mb-0 office365-signin">
                                                    <button className="btn text-center btn-block" type="button"
                                                        onClick={handleGoogleSignIn} >
                                                        <img width="20" src={googleImage} alt="google" style={{marginRight: "10px"}} />
                                                        <span> Sign in with Google</span>
                                                    </button>
                                                </div>

                                                <div className="form-group form-row mt-3 mb-0 office365-signin">
                                                    <button className="btn text-center btn-block" type="button"
                                                        onClick={handleMicrosoftSignIn} >
                                                        <img width="20" src={office365} alt="office365" style={{marginRight: "10px"}} />
                                                        <span> Sign in with Office 365</span>
                                                    </button>
                                                </div>

                                            </form>

                                            :
                                            <form className="theme-form">
                                                <div className="form-group">
                                                    <label className="col-form-label pt-0">{Passcode}</label>
                                                    <input className="form-control signin-input" type="text" name="passcode"
                                                        value={passcode}
                                                        onChange={e => setPasscode(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group form-row mt-3 mb-0">
                                                    <button className="btn btn-primary btn-block signin-button"
                                                        type="button" onClick={() => secondFactorAppLogin()} >{Authenticate}</button>
                                                </div>
                                                <p className="go-back" onClick={goBack}>Go Back</p>
                                            </form>
                                }

                            </div>

                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default withRouter(AppSignin);

const styles = {
    signInButton: {
        padding: "10px 10px",
        backgroundColor: "#4285F4",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        margin: "10px",
    },
};

