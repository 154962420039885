import React from 'react'
import { Redirect } from 'react-router'

import Auxi from '../../hoc/Auxi'

import './Layout.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


import { Button, Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap'
// import AnsweringRuleModal from '../../components/Widgets/Modal/AnseringRuleModal'
//import Navbar from 'navbar-react'




class CustomAppTabLayout extends React.Component {

    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            displaySettingsMenu: "None",
            userName: 'Daniyal',
            performLogout: false,
            openVoiceMails: false,
            showAnsweringRuleModal: false,
            openIndex: false,
            openVmailSettings: false,
            openEngage: false,
            openCrexendoHD: false,
            openLandingPage: false,
            displaySecondSettingsMenu: "None",
        }
    }


    toggleSettingsHandler = (event) => {
        event.preventDefault();
        if (this.state.displaySettingsMenu === "None") {
            this.setState({ displaySettingsMenu: "block" });
        }
        else {
            this.setState({ displaySettingsMenu: "None" });
        }
    }

    toggleSecondSettings = (evt) => {
        evt.preventDefault();
        if (this.state.displaySecondSettingsMenu === "None") {
            this.setState({ displaySecondSettingsMenu: "flex" });
        }
        else {
            this.setState({ displaySecondSettingsMenu: "None" });
        }
    }

    indexHandler = (event) => {
        event.preventDefault();
        this.setState({ openLandingPage: true });
    }

    voiceHandler = (evt) => {
        evt.preventDefault();
        this.setState({ openVoiceMails: true });
    }

    crexendoHdHandler = (evt) => {
        evt.preventDefault();
        this.setState({ openCrexendoHD: true });
    }

    vmailSettingsHandler = (evt) => {
        evt.preventDefault();
        this.setState({ openVmailSettings: true });
    }

    answeringRuleHandler = () => {
        this.setState({ displaySettingsMenu: "None" });
        this.setState({ showAnsweringRuleModal: true });
    }

    closeAnsweringRuleModal = () => {
        this.setState({ showAnsweringRuleModal: false });
    }

    render() {

        let url = decodeURIComponent(window.location.href)

        url = url.substring(
            url.lastIndexOf("?username="),
            url.length
        );

        let redirect = null;
        const indexPath = "/custom_tab/index" + url;
        const voiceMailPath = "/custom_tab/voice_messages" + url;
        const vmailSettingsPath = "/custom_tab/vmail_settings" + url;
        const vmailEngagePath = "/custom_tab/engage" + url;
        const crexendoHDPath = "/snap_hd" + url;

        // const md5 = require('md5');

        if (this.state.openLandingPage) {
            this.setState({ openLandingPage: false });
            redirect = <Redirect to={indexPath} />;
        }

        if (this.state.openVoiceMails) {
            this.setState({ openVoiceMails: false });
            redirect = <Redirect to={voiceMailPath} />;
        }


        if (this.state.openVmailSettings) {
            this.setState({ openVmailSettings: false });
            redirect = <Redirect to={vmailSettingsPath} />;
        }

        if (this.state.openEngage) {
            this.setState({ openEngage: false });
            redirect = <Redirect to={vmailEngagePath} />;
        }
        return (
            <Auxi >
                {redirect}
                <div className="app-layout">
                    <Navbar collapseOnSelect expand="lg" className="app-navbar" variant="light" >

                        <Nav.Link href="" style={{ color: "black" }} onClick={this.indexHandler}><FontAwesomeIcon icon={faArrowLeft} /> </Nav.Link>
                    </Navbar>
                </div>
            </Auxi>
        );
    }

}

export default CustomAppTabLayout;
