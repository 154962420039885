import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import {Card,CardBody,CardHeader } from 'reactstrap';
import Breadcrumb from '../common/breadcrumb';
import axios from 'axios';
import {Redirect} from 'react-router';

import '../../assets/scss/app-styles/users.scss'

class Details extends React.Component {

	constructor(props) {
		super(props);
		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.saveHandler = this.saveHandler.bind(this);
		this.cancelHandler = this.cancelHandler.bind(this);
	}

	state = {
		data: {
		},
		backToIndex: false,
        style: 'custom-spinner',
	};
	headers = {
        headers: {
            Token: localStorage.getItem("token"),
        }
    }


	inputChangeHandler(event) {
		var data = this.state.data;
		data[event.target.name] = event.target.value;
		this.setState({ data });

	}

    getSubscribers = (evt) => {
        const payLoad = {
            user_id: JSON.parse(localStorage.getItem('creds')).id
        }

        axios.post(`${process.env.REACT_APP_API_URL}getCloudIds`, payLoad, this.headers)
        .then(response => {
            let items = [];
            items.push(<option value={""}></option>);
            response.data.map((row)=>(
                items.push(<option value={row.id}>{row.cloud_id}</option>)
            ));
            this.subscribersItems = items;
            this.setState({subscribersItems: items})
        }).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
        });
    }

	componentDidMount() {
		if(!this.props.location.state){
			this.setState({backToIndex: true});
            this.setState({style: 'custom-spinner-hide'});
			return;
		}
        if(JSON.parse(localStorage.getItem('creds')).cloud_id === process.env.REACT_APP_CLOUD_ID){
            this.getSubscribers()
        }
		if (this.props.location.state.id) {
			this.setState({style: 'custom-spinner'});
			const payLoad = {
				id: this.props.location.state.id,
				user_id: localStorage.getItem('user_id')
			};
			axios.post(`${process.env.REACT_APP_API_URL}userDetail`, payLoad, this.headers)
			.then(response => {
				var data = this.state.data;
				data = response.data;
				this.setState({ data });
				this.setState({style: 'custom-spinner-hide'});
			}).catch(err => {
				toast.error(err.response.data);
                this.setState({style: 'custom-spinner-hide'});
			});
		} else {

			this.setState({style: 'custom-spinner-hide'});
		}

	}

	saveHandler(event) {
		event.preventDefault();
		if (!this.state.data.name || !this.state.data.email || !this.state.data.username || !this.state.data.phone_number) {
            toast.error('Fields with * are mandatory!');
            return;
		}

        if(!this.props.location.state.id && !this.state.data.password){
            toast.error('Password is mandatory to add user!');
            return;
        }

        if(!this.props.location.state.id && this.state.data.password.length < 5){
            toast.error('Password should be atleast 6 characters long!');
            return;
        }

		this.setState({style: 'custom-spinner'});
		var data = this.state.data;
		this.setState({ data });
		const payLoad = this.state.data;
        payLoad["user_id"] = JSON.parse(localStorage.getItem('creds')).id;
		axios.post(`${process.env.REACT_APP_API_URL}createUpdateUser`, payLoad, this.headers)
		.then(response => {
			this.setState({ backToSummary: true });
            this.setState({style: 'custom-spinner-hide'});
		}).catch(err => {
            toast.error(err.response.data);
            this.setState({style: 'custom-spinner-hide'});
		});
	}

	cancelHandler() {
		this.setState({ backToSummary: true });
	}

	render() {

		let redirect = null;
		if (this.state.backToSummary) {
			this.setState({ backToSummary: false });
			redirect = <Redirect to={{
				pathname: '/users',
			}}/>;
		}
		if(this.state.backToIndex){
			redirect = <Redirect to = {{
				pathname: "/dashboard"
			}} />;
		}

		return (
            <Fragment>
                {redirect}
                <Breadcrumb parent="Users" title="Details" />
                <div className={"loader-box " + this.state.style} >
                    <div className="loader">
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    <div className="line bg-primary"></div>
                    </div>
                </div>
                <div className="container-fluid main-container">
                    <div className="col-md-2 col-sm-0"></div>
                    <Card className="col-md-8 col-sm-12">
                        <CardHeader>
                            <h5>{this.props.location.state && this.props.location.state.id? "Edit User" : "Add User"}</h5>
                        </CardHeader>
                        <CardBody className="dropdown-basic">
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Name
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="name"
                                        onChange={this.inputChangeHandler} value={this.state.data.name}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Email
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="email"
                                        onChange={this.inputChangeHandler} value={this.state.data.email}/>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Username
                                    </label> <span> * </span>
                                <input type="text" className="form-control" name="username"
                                    onChange={this.inputChangeHandler} value={this.state.data.username}/>
                                </div>
                                {
                                    this.props.location.state && !this.props.location.state.id ?
                                        <div className="col-md-6">
                                            <label className="float-left">
                                                Password
                                            </label> <span> * </span>
                                            <input type="password" className="form-control" name="password"
                                                onChange={this.inputChangeHandler} value={this.state.data.password}/>
                                        </div>
                                    : null
                                }
                                
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label className="float-left">
                                        Phone Number
                                    </label> <span> * </span>
                                    <input type="text" className="form-control" name="phone_number"
                                        onChange={this.inputChangeHandler} value={this.state.data.phone_number}/>
                                </div>

                                <div className="col-md-6 custom-radio">
                                    <label className="float-left">
                                        Type
                                    </label>
                                    <select  className="form-control"
                                        onChange={this.inputChangeHandler} name="user_type" 
                                        value={this.state.data.user_type}>
                                        <option value={""}></option>
                                        <option value={"admin"}>Admin</option>
                                        <option value={"simple user"}>Simple User</option>
                                    </select>
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-md-6">
                                    <label> Active  </label>
                                    <div className="active-radios" onChange={this.inputChangeHandler}>
                                        <input type="radio" value="true" name="active"
                                            checked={this.state.data.active}
                                            /> Yes
                                        <input className="margin-left"
                                            type="radio" value="false" name="active"
                                            checked={!this.state.data.active}/> No
                                    </div>
                                </div>

                                {
                                    this.state.subscribersItems? 
                                        <div className="col-md-6">
                                            <label> Subscriber  </label>
                                            <select  className="form-control"
                                                onChange={this.inputChangeHandler} name="subscriber_id" 
                                                value={this.state.data.subscriber_id}>
                                                {this.state.subscribersItems}
                                            </select>
                                        </div>
                                    : null
                                }
                            </div>

                            <div className="row form-group">
                                <div className="col-md-12">

                                    <button type="button" onClick={this.cancelHandler} className="button-custom btn shadow-2 btn-secondary btn-width">
                                        CANCEL
                                    </button>
                                    <button type="button" onClick={this.saveHandler} className="button-custom btn shadow-2 btn-success btn-width">
                                        SAVE
                                    </button>

                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <div className="col-md-2 col-sm-0"></div>
                </div>
            </Fragment>
		);

	}
}

export default Details;
