import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../components/Widgets/Spinner';
import { Authenticate, SignIn, Username, Password, CloudId, Passcode } from '../constant';

const AuthCallback = () => {
    const location = useLocation();
    const [authData, setAuthData] = useState(null);
    const [subscribers, setSubscribers] = useState([]);
    const [ssoType, setSsoType] = useState('');
    const [ssoToken, setSsoToken] = useState('');
    const [accessToken, setAccessToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cloudId, setCloudId] = useState(process.env.REACT_APP_CLOUD_ID);
    const [passcode, setPasscode] = useState("");
    const [mfa, setMfa] = useState({});

    useEffect(() => {
        // Capture the redirect URI hash and extract parameters
        const hash = location.hash;
        if (hash) {
            const params = new URLSearchParams(hash.substring(1)); // Remove '#' and parse the parameters
            // Extract token and other details from the hash
            let idToken = params.get("id_token");
            let accessToken = params.get("access_token");
            if(accessToken){
                //accessToken = params.get("access_token");
                ssoSignin(accessToken, 'office')
                return;
            }

            ssoSignin(idToken, 'google')

            
        }
    }, [location]);

    const ssoSignin = async (token, type, subscriberLogin) => {
        setLoading(true);
        const creds = {
            "cloud_id": process.env.REACT_APP_CLOUD_ID,
            "type": type,
            "idToken": token
        };
        if (subscriberLogin) {
            creds.subscriber_login = subscriberLogin;
        }
        axios.post(`${process.env.REACT_APP_API_URL}ssoSignin`, creds)
            .then(response => {
                setLoading(false);
                if (response.data.subscribers && response.data.subscribers.length > 0) {
                    console.log("Setting subscribers")
                    setSubscribers(response.data.subscribers)
                    setSsoType(type)
                    setSsoToken(token)
                    return;
                }
                if (response.data.mfa === "mfa_required") {
                    setUsername(response.data.username);
                    setPassword(type);
                    setMfa(response.data);
                    return

                }
                let code = "csc:" + response.data.username + ":" + btoa(type) + "@" + cloudId
                if (process.env.REACT_APP_BUILD === "dev") {
                    code += "*"
                }
                window.location.href = code

                toast.success("Signing In Please Wait!");
            })
            .catch(err => {
                setLoading(false);
                toast.error("Failed to login");
                console.log(err);

            });
    }
    const handleSecondSso = (subscriberLogin) => {
        ssoSignin(ssoToken, ssoType, subscriberLogin)
    }
    const goBack = () => {
        setMfa({});
        setSubscribers([]);
    }
    const secondFactorAppLogin = () => {
        setLoading(true);
        const payLoad = {
            "cloud_id": cloudId,
            "username": username,
            "password": password,
            "passcode": passcode,
            "mfa_type": mfa.mfa_type,
            "mfa_vendor": mfa.mfa_vendor,
            "access_token": mfa.access_token,
        }
        axios.post(`${process.env.REACT_APP_API_URL}secondFactorAppSignin`, payLoad)
            .then(response => {
                setLoading(false);
                let code = "csc:" + username + ":" + btoa(password) + "@" + cloudId
                if (process.env.REACT_APP_BUILD === "dev") {
                    code += "*"
                }
                window.location.href = code;
                toast.success("Signing In Please Wait!");
            })
            .catch(err => {
                setLoading(false);
                toast.error("Failed to login");
                console.log(err);

            });
    }
    return (
        <div style={{ padding: "20px" }}>
            {/* {authData ? (
                <div>
                    <h1>Redirecting with token...</h1>
                </div>
            ) : (
                <p>Processing authentication...</p>
            )} */}

            <div className="text-center">
                {loading && (
                    <Spinner />
                )}
                <h4>{SignIn}</h4>
                <h6>
                    {
                        subscribers.length > 0?
                            "Choose a Subscriber to Sign In As"
                            :
                            "Loading..."
                    }
                </h6>
            </div>
            {
                subscribers.length > 0 ?

                    <div>
                        <div className="subscriber-list">
                            {
                                subscribers.map((subscriber, index) => {
                                    return (
                                        <div key={index} className="subscriber" onClick={e => handleSecondSso(subscriber.subscriber_login)} >
                                            <span>{subscriber.subscriber_login}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <p className="go-back" onClick={goBack}>Go Back</p>
                    </div>
                    :
                    Object.keys(mfa).length === 0 ?

                        <form className="theme-form">




                        </form>

                        :
                        <form className="theme-form">
                            <div className="form-group">
                                <label className="col-form-label pt-0">{Passcode}</label>
                                <input className="form-control signin-input" type="text" name="passcode"
                                    value={passcode}
                                    onChange={e => setPasscode(e.target.value)}
                                />
                            </div>
                            <div className="form-group form-row mt-3 mb-0">
                                <button className="btn btn-primary btn-block signin-button"
                                    type="button" onClick={() => secondFactorAppLogin()} >{Authenticate}</button>
                            </div>
                            <p className="go-back" onClick={goBack}>Go Back</p>
                        </form>
            }
        </div>
    );
};

export default AuthCallback;
